import { LOGIN_ERROR, LOGIN_SUCCESS, LOGOUT_OK, SESSION_ERROR, SESSION_SUCCESS } from "./auth/actionType"

/**
 * reducer
 */
const initialState = {
  is_session_available: false,
  on_logout: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS: {
      console.log("LOGIN_SUCCESS")
      state = {
        ...state,
        is_session_available: false,
        on_logout: false,
      }
      break
    }
    case LOGIN_ERROR: {
      state = {
        ...state,
        is_session_available: false,
        on_logout: true,
      }
      break
    }
    case SESSION_ERROR: {
      state = {
        ...state,
        is_session_available: true,
        on_logout: false,
      }
      break
    }
    case SESSION_SUCCESS: {
      state = {
        ...state,
        is_session_available: false,
        on_logout: false,
      }
      break
    }
    case LOGOUT_OK: {
      state = {
        ...state,
        is_session_available: false,
        on_logout: true,
      }
      break
    }
    default:
      return state
  }
  return state
}