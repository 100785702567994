import Axios from "axios"
import store from "./src/store/store"
import { SESSION_ERROR } from "./src/store/auth/actionType"
//
// const dispatch = useDispatch()

export const axiosAPI = Axios.create({
  baseURL: process.env["API_ENDPOINT"],
  withCredentials: true,
  headers: {
    "Accept": "application/json",
  }
})

export const axiosAPIUnAuth = Axios.create({
  baseURL: process.env["API_ENDPOINT"],
  headers: {
    "Accept": "application/json",
  }
})

// API実行時はボタン要素を非活性化にする
axiosAPI.interceptors.request.use(request => {
  [].forEach.call(document.getElementsByTagName("button"), element => {
    element.style["opacity"] = 0.5
    element.style["pointer-events"] = "none"
  })
  return request
}, request => {
  [].forEach.call(document.getElementsByTagName("button"), element => {
    element.style["opacity"] = 0.5
    element.style["pointer-events"] = "none"
  })
  return request
})

// API応答があった場合はボタン非活性化を解除する
axiosAPI.interceptors.response.use((response) => {
  [].forEach.call(document.getElementsByTagName("button"), element => {
    element.style["opacity"] = 1
    element.style["pointer-events"] = "auto"
  })
  return response.data
}, (error) => {
  [].forEach.call(document.getElementsByTagName("button"), element => {
    element.style["opacity"] = 1
    element.style["pointer-events"] = "auto"
  })

  let retVal = error
  switch (error.response?.status) {
    case 404:
      retVal = "データが見つかりません"
      break
    case 419:
      store.dispatch({ type: SESSION_ERROR })
      break
    case 422:
      if (error.response.data && error.response.data.errors) {
        retVal = Object.values(error.response.data.errors).join("\n")
      }
      break
    case 500:
      if (error.response.data && error.response.data.message) {
        retVal = error.response.data
      }
      break
    default:
      store.dispatch({ type: SESSION_ERROR })
      break
  }

  return Promise.reject(error.response.data)
})
