import { combineReducers, createStore } from "redux"
import Reducer from "./reducer"

const reducer = combineReducers({
  user: Reducer
})

export type State = ReturnType<typeof reducer>

// export default store => createStore(reducer)

const store = createStore(reducer)
export default store